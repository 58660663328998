import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Dropdown from "components/Header/imageComponents/Dropdown";
import useScreenMobile from "hooks/useScreen";

import logo from "assets/images/header/companyLogo.svg";
import contactIcon from "assets/images/header/contact.svg";
import development from "assets/images/header/systemDev.svg";
import integration from "assets/images/header/systemIntegration.svg";
import support from "assets/images/header/support.svg";
import hamburger from "assets/images/header/hamburger.svg";
import menu from "assets/images/header/menu.svg";
import close from "assets/images/header/close.svg";

import "./header.scss";

const Header = () => {
  const [dropActive, setDropActive] = useState(false);
  const [closeActive, setCloseActive] = useState(false);
  const [menuDrop, setMenuDrop] = useState(false);

  const isTab = useScreenMobile({ size: 992 });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTab) {
      setCloseActive(false);
      setMenuDrop(false);
    }
  }, [isTab]);

  useEffect(() => {
    if (closeActive || dropActive) {
      document.body.style.overflow = "hidden";
      document.addEventListener("click", handleClickOutside);
    } else {
      document.body.style.overflow = "unset";
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeActive, dropActive]);

  const handleNavigation = (page) => {
    navigate(page);
    setCloseActive(false);
  };

  const handleContactClick = (idName) => {
    navigate("/", { replace: true });
    setTimeout(() => {
      const formElement = document.getElementById(idName);
      setCloseActive(false);
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  const handleClickOutside = (event) => {
    const exportBtn = event.target.closest(".header");
    if (!exportBtn) {
      setCloseActive(false);
    }
  };

  return (
    <>
      <section className="header">
        <div className="container headerContainer">
          <div className="content">
            <div className="logo">
              <img
                src={logo}
                alt="PAPLAJ SOFTWARE SERVICES"
                onClick={() => handleNavigation("/")}
              />
            </div>
            {!isTab && (
              <div className="nav">
                <p className="navContent" onClick={() => navigate("/")}>
                  Home
                </p>
                <div
                  className="navContent service"
                  onMouseEnter={() => setDropActive(true)}
                  onMouseLeave={() => setDropActive(false)}
                >
                  <div className="displayContent">
                    <p
                      className="navContent serviceText"
                      style={{ color: dropActive ? "#45C1C5" : "#0E0E0F" }}
                    >
                      Services
                    </p>
                    <div className="serviceDrop">
                      <Dropdown
                        fillColor={dropActive ? "#45C1C5" : "#0E0E0F"}
                      />
                    </div>
                  </div>
                  {dropActive && (
                    <div className="dropContent">
                      <div className="dropContainer">
                        <div
                          className="dropItems"
                          onClick={() => handleNavigation("/systemDevelopment")}
                        >
                          <div className="imgSection">
                            <div className="imgDropWrapper">
                              <img src={development} alt="Development" />
                            </div>
                          </div>
                          <div className="textSection">
                            <p className="title">System Development</p>
                            <p className="textContent">
                              Custom-built CTRM systems for seamless
                              scalability.
                            </p>
                          </div>
                        </div>

                        <div
                          className="dropItems"
                          onClick={() => handleNavigation("/systemIntegration")}
                        >
                          <div className="imgSection">
                            <div className="imgDropWrapper">
                              <img src={integration} alt="Integration" />
                            </div>
                          </div>
                          <div className="textSection">
                            <p className="title">System Integration</p>
                            <p className="textContent">
                              Integrating CTRM with systems for unified
                              workflows.
                            </p>
                          </div>
                        </div>

                        <div
                          className="dropItems"
                          onClick={() => handleNavigation("/productionSupport")}
                        >
                          <div className="imgSection">
                            <div className="imgDropWrapper">
                              <img src={support} alt="support" />
                            </div>
                          </div>
                          <div className="textSection">
                            <p className="title">Production Support</p>
                            <p className="textContent">
                              Ongoing support to ensure smooth operations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="navContent">
                  <div onClick={() => handleContactClick("offer")}>
                    Our Offerings
                  </div>
                </div>
              </div>
            )}
          </div>
          {isTab ? (
            <div
              className="hamburger"
              onClick={() => {
                setCloseActive(!closeActive);
                setMenuDrop(false);
              }}
            >
              <img
                src={closeActive ? close : hamburger}
                alt={closeActive ? "close" : "menu"}
              />
            </div>
          ) : (
            <div className="contact" onClick={() => handleContactClick("form")}>
              <button className="contactBtn">
                <img src={contactIcon} alt="contact" />
                <span className="contactText">Contact Us</span>
                <div className="animation"></div>
              </button>
            </div>
          )}

          {isTab && closeActive && (
            <div className="menuModal" onClick={() => setCloseActive(false)}>
              <div
                className={`menu ${closeActive ? "menu-active" : ""}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="menuContainer">
                  <div className="menuContent service">
                    <div
                      className="menuContent offer"
                      onClick={() => {
                        handleContactClick("/");
                        setMenuDrop(false);
                      }}
                    >
                      <div className="text">
                        <p>Home</p>
                      </div>
                    </div>
                    <div
                      className="topContent"
                      onClick={() => setMenuDrop(!menuDrop)}
                    >
                      <div className="text">
                        <p>Services</p>
                      </div>
                      <div className="menuImg">
                        <div className="menuImgWrapper">
                          <img src={menu} alt="menu" />
                        </div>
                      </div>
                    </div>
                    {menuDrop && (
                      <div className="dropContent">
                        <div className="dropContainer">
                          <div
                            className="dropItems"
                            onClick={() => {
                              handleNavigation("/systemDevelopment");
                              setMenuDrop(false);
                            }}
                            tabIndex={0}
                          >
                            <div className="imgSection">
                              <div className="imgDropWrapper">
                                <img src={development} alt="Development" />
                              </div>
                            </div>
                            <div className="textSection">
                              <p className="title">System Development</p>
                              <p className="textContent">
                                Custom-built CTRM systems for seamless
                                scalability.
                              </p>
                            </div>
                          </div>

                          <div
                            className="dropItems"
                            onClick={() => {
                              handleNavigation("/systemIntegration");
                              setMenuDrop(false);
                            }}
                            tabIndex={0}
                          >
                            <div className="imgSection">
                              <div className="imgDropWrapper">
                                <img src={integration} alt="Integration" />
                              </div>
                            </div>
                            <div className="textSection">
                              <p className="title">System Integration</p>
                              <p className="textContent">
                                Integrating CTRM with systems for unified
                                workflows.
                              </p>
                            </div>
                          </div>

                          <div
                            className="dropItems"
                            onClick={() =>
                              handleNavigation("/productionSupport")
                            }
                            tabIndex={0}
                          >
                            <div className="imgSection">
                              <div className="imgDropWrapper">
                                <img src={support} alt="support" />
                              </div>
                            </div>
                            <div className="textSection">
                              <p className="title">Production Support</p>
                              <p className="textContent">
                                Ongoing support to ensure smooth operations.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="menuContent offer"
                    onClick={() => {
                      handleContactClick("offer");
                      setMenuDrop(false);
                    }}
                  >
                    <div className="text">
                      <p>Our Offerings</p>
                    </div>
                  </div>
                  <div className="menuContent contact contactEdit">
                    <button
                      className="contactBtn"
                      onClick={() => handleContactClick("form")}
                    >
                      <img src={contactIcon} alt="contact" />
                      <span className="contactText">Contact Us</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Header;
