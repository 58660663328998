import "./msgButton.scss";

const MsgButton = ({ msgButtonData, handleButton = () => {} }) => {
  return (
    <>
      <div
        className="menuContent contact contactEdit"
        onClick={() => {
          handleButton();
        }}
      >
        <button className="contactBtn">
          <img src={msgButtonData.icon} alt="contact" />
          <span className="contactText">{msgButtonData.text}</span>
          <div className="animation"></div>
        </button>
      </div>
    </>
  );
};

export default MsgButton;
