import { Link } from "react-router-dom";

import MsgButton from "components/MsgButton/msgButton";

import backIcon from "assets/images/msgButton/back.svg";

import notFoundImage from "assets/images/notFound/notFoundIcon.png";

import "./pageNotFound.scss";

const PageNotFound = () => {
  const msgButtonData = {
    icon: backIcon,
    text: "Go Back"
  };

  return (
    <>
      <section className="notFound">
        <div className="notFoundContainer">
          <div className="imageSection">
            <img src={notFoundImage} alt="Page Not Found" />
          </div>
          <div className="bottomContent">
            <div className="notFoundText">
              <h4 className="oops">Oops! Page Not Found</h4>
              <p className="notFoundMsg">
                It looks like the page you&apos;re trying to reach doesn&apos;t
                exist or has been moved
              </p>
            </div>
            <div className="goHome">
              <Link to="/">
                <MsgButton msgButtonData={msgButtonData} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
