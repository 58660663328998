import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FadeUp from "components/FadeUp/FadeUp";

import termsFile from "../../assets/pdf/TermsConditions.pdf";
import policyFile from "../../assets/pdf/PrivacyPolicy.pdf";

import companyLogo from "assets/images/header/companyLogo.svg";
import linkedIn from "assets/images/footer/linkedIn.svg";
import forward from "assets/images/footer/forward.svg";
import hoverForward from "assets/images/footer/hoverForward.svg";
import hoverLinkedIn from "assets/images/footer/hoverLinkedIn.svg";

import "./footer.scss";

const Footer = () => {
  const [forwardImg, setForwardImg] = useState("");
  const [isHover, setIsHover] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Load the GoDaddy script dynamically after component mounts
    const existingScript = document.getElementById("godaddy-seal-script");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "https://seal.godaddy.com/getSeal?sealID=VgfPPWdyU7VUvoBpR7mjNTMAr79U7cD62Zhc74YPAlSASYizo15oYnV4TQif";
      script.async = true;
      script.id = "godaddy-seal-script"; // Set an ID for easy reference
      document.getElementById("siteseal").appendChild(script);
    }
  }, []);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleHover = (icon) => {
    setIsHover(icon);
  };

  const handleHoverLeave = () => {
    setIsHover("");
  };

  const handleForward = (type) => {
    if (type === "") setForwardImg("");

    if (type === "number") setForwardImg(type);

    if (type === "email") setForwardImg(type);

    if (type === "dir") setForwardImg(type);
  };

  const handleNavigation = (page) => {
    navigate(page);
  };

  const openPDF = (filename) => {
    window.open(filename, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <section className="footer">
        <div className="container footerContainer">
          <div className="footerContent">
            <div className="company">
              <FadeUp>
                <div className="logo">
                  <div className="imageWrapper">
                    <img src={companyLogo} alt="" />
                  </div>
                  <div className="companyText">
                    <p>
                      Innovative technical solutions for Agri CTRM systems,
                      ensuring efficient operations and seamless integration.
                    </p>
                  </div>
                </div>
              </FadeUp>
              <FadeUp>
                <div className="socials">
                  <div
                    className="imageWrapper"
                    onMouseEnter={() => handleHover("linkedin")}
                    onMouseLeave={handleHoverLeave}
                  >
                    <a
                      href="https://www.linkedin.com/company/paplaj-software-services/"
                      target="_blank"
                      rel="nooppener noreferrer"
                    >
                      <img
                        src={isHover === "linkedin" ? hoverLinkedIn : linkedIn}
                        alt="LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </FadeUp>
            </div>

            <FadeUp>
              <div className="service">
                <div className="title">
                  <p>Services</p>
                </div>
                <div className="items">
                  <p
                    onClick={() => {
                      handleNavigation("/systemDevelopment");
                      scrollTop();
                    }}
                  >
                    System Development
                  </p>
                  <p
                    onClick={() => {
                      handleNavigation("/systemIntegration");
                      scrollTop();
                    }}
                  >
                    System Integration
                  </p>
                  <p
                    onClick={() => {
                      handleNavigation("/productionSupport");
                      scrollTop();
                    }}
                  >
                    Production Support
                  </p>
                </div>
              </div>
            </FadeUp>

            <div className="info">
              <FadeUp>
                <div className="contacts">
                  <p className="title">Contact</p>
                  <div
                    className="number"
                    onMouseEnter={() => handleForward("number")}
                    onMouseLeave={() => handleForward("")}
                  >
                    <a href="tel:+91-9468865444" className="numberInfo">
                      +91-9468865444
                    </a>
                    <div className="imageWrapper">
                      <img
                        src={forwardImg === "number" ? hoverForward : forward}
                        alt="forward"
                      />
                    </div>
                  </div>
                </div>
              </FadeUp>
              <FadeUp>
                <div className="mail">
                  <p className="title">Mail</p>
                  <div
                    className="mailId"
                    onMouseEnter={() => handleForward("email")}
                    onMouseLeave={() => handleForward("")}
                  >
                    <a
                      href="mailto:contactus@paplajsoftware.com"
                      className="mailIdInfo"
                    >
                      contactus@paplajsoftware.com
                    </a>
                    <div className="imageWrapper">
                      <img
                        src={forwardImg === "email" ? hoverForward : forward}
                        alt="forward"
                      />
                    </div>
                  </div>
                </div>
              </FadeUp>
            </div>
            <FadeUp>
              <div className="address">
                <div className="addressInfo">
                  <p className="title">Address</p>
                  <p className="content">
                    Paplaj Software Services Pvt. Ltd. C-73-A, Shiv Officer
                    Colony, Jagatpura, Jaipur, Rajasthan, India - 302017
                  </p>
                </div>
                <div className="direction">
                  <a
                    href="https://www.google.com/maps/place/26%C2%B049'59.1%22N+75%C2%B051'34.8%22E/@26.8330833,75.8596667,17z/data=!3m1!4b1!4m4!3m3!8m2!3d26.8330833!4d75.8596667?hl=en&entry=ttu&g_ep=EgoyMDI0MTAyMi4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                    rel="nooppener noreferrer"
                  >
                    <div
                      className="directionContainer"
                      onMouseEnter={() => handleForward("dir")}
                      onMouseLeave={() => handleForward("")}
                    >
                      <p className="directionText">Get Directions</p>
                      <img
                        src={forwardImg === "dir" ? hoverForward : forward}
                        alt="forward"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </FadeUp>
          </div>
          <FadeUp>
            <div className="copyRight">
              <div className="companyName">
                <p>©2024 Paplaj Software Services.</p>
              </div>
              <div className="footerGodaddy">
                <span id="siteseal"></span>
              </div>
              <div className="termsContainer">
                <div className="terms">
                  <p
                    className="termsAndPolicy"
                    onClick={() => openPDF(termsFile)}
                  >
                    Terms & Conditions
                  </p>
                </div>
                <div className="policy">
                  <p
                    className="termsAndPolicy"
                    onClick={() => openPDF(policyFile)}
                  >
                    Privacy Policy
                  </p>
                </div>
              </div>
            </div>
          </FadeUp>
        </div>
      </section>
    </>
  );
};

export default Footer;
