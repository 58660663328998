const Dropdown = ({ fillColor }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.828 9.17064C15.0568 9.39947 15.0568 9.77041 14.828 9.99924L10.9217 13.9055C10.6929 14.1343 10.3219 14.1343 10.0931 13.9055L6.18684 9.99924C5.95801 9.77041 5.95801 9.39947 6.18684 9.17064C6.41567 8.94182 6.78661 8.94182 7.01543 9.17064L10.5074 12.6625L13.9993 9.17064C14.2281 8.94182 14.5991 8.94182 14.828 9.17064Z"
        fill={fillColor} // Fill color passed from parent
      />
      <path
        d="M10.7319 14.0332C10.6628 14.0619 10.587 14.0777 10.5076 14.0777C10.5839 14.0777 10.6602 14.0628 10.7319 14.0332Z"
        fill={fillColor} // Same fill color for both paths
      />
    </svg>
  );
};

export default Dropdown;
