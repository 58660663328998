import Footer from "components/Footer/index";
import Header from "components/Header/index";
import React from "react";

const AppLayouts = (props) => {
  const { headerRequired = true, footerRequired = true } = props;
  return (
    <div>
      {headerRequired && <Header />}
      <div>{props.children}</div>
      {footerRequired && <Footer />}
    </div>
  );
};

export default AppLayouts;
