import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

import AppLayouts from "layouts/AppLayout";
import PageNotFound from "components/PageNotFound/PageNotFound";

import "styles/common.scss";

const Home = lazy(() => import("containers/home/index"));
const Production = lazy(() => import("containers/productionSupport/index"));
const Development = lazy(() => import("containers/systemDevelopment/index"));
const Integration = lazy(() => import("containers/systemIntegration/index"));

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_KEY); //eslint-disable-line
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayouts>
                  <Home />
                </AppLayouts>
              }
            />
            <Route
              path="/productionSupport"
              element={
                <AppLayouts>
                  {" "}
                  <Production />{" "}
                </AppLayouts>
              }
            />
            <Route
              path="/systemDevelopment"
              element={
                <AppLayouts>
                  {" "}
                  <Development />{" "}
                </AppLayouts>
              }
            />
            <Route
              path="/systemIntegration"
              element={
                <AppLayouts>
                  {" "}
                  <Integration />{" "}
                </AppLayouts>
              }
            />
            <Route
              path="/*"
              element={
                <AppLayouts headerRequired={false} footerRequired={false}>
                  <PageNotFound />
                </AppLayouts>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
